import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <div style={styles.container}>
      <iframe style={styles.frame} src="https://docs.google.com/document/d/e/2PACX-1vT3tOzKFurBbKBlX9MUVW6cqZ-0uTnksYQBHeAV4depx8AvhEcmO6x38dpQLN_KEoe1hrrWW6ZdXwqy/pub?embedded=true"></iframe>
    </div>
  </Layout>
)

const styles = {
  frame: {
    width: '100%',
    height: '100vh',
    overflow: 'hidden',
    borderRadius: 64,
  }
}

export default NotFoundPage