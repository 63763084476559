import React from "react"
import { Container, Row, Col as Column, Hidden } from 'react-grid-system';
import { Link } from "gatsby"

import Layout from "../components/layout"

import SEO from "../components/seo"

import backgroundImage from '../images/backgroundImage@2x.png';
import phoneImage from '../images/phoneScreenshot@2x.png';
import playBadge from '../images/googlePlay.svg';
import appleBadge from '../images/appleStore.svg';
import logo from '../images/logo.png';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div style={styles.centerContent}>
      <Container>
        <Row align="center" style={{ minHeight: '100vh'}}>
          <Column md={12} lg={6} style={{ margin: '36px 0'}}>
            <img src={logo} alt="8:36pm Logo" style={styles.logo} />
            <h1 style={styles.bigText}>
              Nightly <br/> Moments <br/> Of Life
            </h1>
            <div style={styles.badgesContainer}>
              <a href="https://play.google.com/store/apps/details?id=com.craigtut.eightthirtysix">
                <img src={playBadge} alt="Download on Google Play" style={{...styles.badge, marginRight: 28 }}/>
              </a>
              <a href="https://apps.apple.com/us/app/8-36pm/id1449113718">
                <img src={appleBadge} alt="Download on Apple Store" style={styles.badge}/>
              </a>
            </div>
          </Column>
          <Column md={12} lg={6} style={{display: 'flex', justifyContent: 'center'}}>
            <Hidden xs sm md>
              <div style={{ width: '100%'}}>
                <img src={backgroundImage} alt="background decoration" style={styles.backgroundImage}/>
              </div>
              <img src={phoneImage} alt="background decoration" style={styles.foregroundImage}/>
            </Hidden>
          </Column>
        </Row>
      </Container>
    </div>
  </Layout>
)

const styles = {
  logo: {
    height: '36px',
    marginBottom: '4em',
  },
  bigText: {
    fontSize: '5.1em',
    fontWeight: '900',
    letterSpacing: '1.47px',
    lineHeight: '1.1em',
  },
  backgroundImage: {
    display: 'block',
  },
  foregroundImage: {
    display: 'flex',
    position: 'absolute',
    width: '50%',
    paddingTop: '20%',
  },
  quote: {
    fontSize: '1.6em',
    fontWeight: '600',
    marginTop: 36,
  },
  badgesContainer: {
    marginTop: 92,
  },
  badge: {
    height: '2.9em',
  },
}

export default IndexPage
