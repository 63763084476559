import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={styles.container}>
      <h1 style={styles.bigText}>Oops, Nothing Here</h1>
    </div>
  </Layout>
)

const styles = {
  bigText: {
    fontSize: '92px',
    fontWeight: '900',
    letterSpacing: '1.47px',
    lineHeight: '100px',
    display: 'flex',
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
  }
}

export default NotFoundPage
