import React, { Component } from 'react';
import { Container, Row, Col as Column } from 'react-grid-system';

class Footer extends Component {
  render() {
    return (
      <footer>
        <Container>
          <Row style={styles.innerContainer} align="center">
            <Column md={8} style={{ display: 'flex', flexDirection: 'row'}}>
              <div style={{ ...styles.text, opacity: 0.6 }}>© {new Date().getFullYear()} MuseLab, LLC</div>
              <div style={styles.listItem}>
                <a style={styles.link} href="/privacy">Privacy Policy</a>
              </div>
              <div style={styles.listItem}>
                <a style={styles.link} href="/terms">Terms of Use</a>
              </div>
            </Column>

            <Column md={4}>
              <a style={styles.link} href="mailto:support@836pm.app">support@836pm.app</a>
            </Column>
          </Row>
        </Container>
      </footer>
    );
  }
}

const styles = {
  innerContainer: {
    minHeight: 92,
  },
  text: {
    color: 'white',
  },
  listItem: {
    marginLeft: '36px',
  },
  link: {
    color: 'white',
    fontWeight: 900,
  }
}

export default Footer;