import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"


class DownloadPage extends React.Component {
  componentDidMount() {
    const os = this.getOS()
    switch(os) {
      case 'Android':
          window.location.replace("https://play.google.com/store/apps/details?id=com.craigtut.eightthirtysix");
          break;
      case 'iOS':
          window.location.replace("https://apps.apple.com/us/app/8-36pm/id1449113718");
          break;
      default: 
        window.location.replace("https://836pm.app");
    }
  }

  getOS() {
    const userAgent = window.navigator.userAgent;
    const platform = window.navigator.platform;
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
    const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
    let os = null;
  
    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }
  
    return os;
  }

  render() {
    return (
      <Layout>
        <SEO title="Download" />
      </Layout>
    );
  }
}

export default DownloadPage;
