/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { setConfiguration } from "react-grid-system";

import Footer from "./Footer";
import "./layout.css";

const margins = [32, 48, 92, 112];
const breakpoints = [600, 768, 1024, 1440];
const containerWidths = breakpoints.map((breakpoint, i) => {
  return breakpoint - (margins[i] * 2);
})

setConfiguration({ breakpoints, containerWidths, gutterWidth: 36 });

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <main id={'main'}>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
